<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList()">搜索</el-button>
			</div>
            <div>
                <el-date-picker size="small" style="width: 230px" @change="searchList()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
            </div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}">
			<el-table-column prop="invite_time" label="邀请时间">
                <template slot-scope="scope">{{ scope.row.invite_time*1000 | formatDate }}</template>
            </el-table-column>
			<el-table-column prop="member_id" label="邀请人id"></el-table-column>
            <el-table-column prop="member_name" label="昵称"></el-table-column>
            <el-table-column prop="role_name" label="角色"></el-table-column>
            <el-table-column prop="master_org_name" label="所属机构"></el-table-column>
            <el-table-column prop="ShortName" label="机构城市"></el-table-column>
            <el-table-column prop="passive_create_at" label="新机构注册时间">
                <template slot-scope="scope">{{ scope.row.passive_create_at*1000 | formatDate }}</template>
            </el-table-column>
            <el-table-column prop="passive_member_name" label="被邀请人"></el-table-column>
            <el-table-column prop="passive_org_id" label="注册机构id"></el-table-column>
            <el-table-column prop="passive_org_name" label="注册机构名称"></el-table-column>
            <el-table-column prop="passive_city_name" label="所在城市"></el-table-column>
			<el-table-column label="新机构状态">
				<template slot-scope="scope"> {{ status[scope.row.passive_state_speed] }} </template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { getInvite } from '@/api/institutional'
	export default {
		name: "List",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
                status: {1: '入驻', 2: '已转发视频课程', 3: '分享视频'},
				// 列表
				searchGist:  [{
					value: 'member_name',
					label: '邀请人昵称'
				}, {
					value: 'org_name',
					label: '邀请人机构名'
				}],
				search: {
					search_gist: 'member_name',
                    desp: '',
                    time: ''
				},
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				getInvite({
					limit: this.limit,
					page: this.currentPage,
					member_name: this.search.search_gist == 'member_name' ? this.search.desp : null,
                    org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
                    start_time: this.$common.setSearchTime(this.search.time)[0],
					end_time: this.$common.setSearchTime(this.search.time)[1],
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err, 222)
				})
			},
			// 搜索
			searchList() {
				this.loading = true
				this.currentPage = 1;
				this.getList();
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			}
		}
	};
</script>
